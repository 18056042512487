@mixin text-icon-button {
    display: inline-block;
    line-height: 1;
    text-align: center;
    font-size: #{16 / $em}rem;
    cursor: pointer;
    padding-left: 1.5rem;
    padding-top: $baseline / 2;
    padding-bottom: $baseline / 2;
    margin-top: $baseline / -2;
    margin-bottom: $baseline / -2;
    color: $anchor-color;
    background-size: 1rem 1rem;
    background-position: left center;
    background-repeat: no-repeat;
    &:hover,
    &:focus {
        color: $anchor-color-hover;
    }
    &.icon-download {
        @include sb-icon-download($anchor-color);
        background-position: left $baseline / 2 - .1rem;
        &:hover,
        &:focus {
            @include sb-icon-download($anchor-color-hover);
        }
    }
}

@mixin button {
    display: inline-block;
    // min-width: 6rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    font-size: #{16 / $em}em;
    cursor: pointer;
    padding: .75em 1em;
    border: 1px solid;
    border-radius: .5rem;
    background-color: transparent;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
    &:hover,
    &:focus {
        border-color: $anchor-color-hover;
    }
    &:hover {
        background-color: $anchor-color-hover;
        color: $text-color-white;
    }
    transition: background-color .2s ease,
                border-color .2s ease;
    &.disabled {
        opacity: 1;
        background-color: $gray;
        border-color: $gray;
        cursor: default;
    }
}
