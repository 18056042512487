// @import '@depot/grid';
// @import '@depot/utility';
// @import '@depot/breakpoint';
// @import '@foundation/util/value';
// @import 'site:icon-mixins';
// @import 'icons';

$message-color: $primary-color !default;
$baseline-offset: 0rem !default;

@mixin feed-controls(
    $line-height: $baseline * 2,
    $font-size: 1rem,
    $input-padding: $baseline / 2 $baseline,
    $input-border-radius: .3rem,
    $input-shadow: 0 2px 20px -3px rgba(0,0,0,0.3),
    $input-background-color: $off-white,
    $input-background-color-focus: $white,
    $input-background-color-hover: rgba(0,0,0,0.05)
) {
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        width: 0px;
        height: 0px;
    }
    label {
        cursor: pointer;
    }
    .feed-controls-form {
        z-index: 20;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size;
        line-height: $line-height;
        margin-top: -$baseline;
    }
    .feed-controls-order {
        position: relative;
        display: flex;
        height: calc(#{$line-height} + #{get-side($input-padding, top)} + #{get-side($input-padding, top)});
        margin-right: 2em;
        margin-top: $baseline;
    }
    .feed-controls-heading {
        margin-right: .5em;
        padding-top: get-side($input-padding, top);
        padding-bottom: get-side($input-padding, bottom);
        cursor: pointer;
        &::after {
            z-index: 2;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:focus {
            outline: 0;
        }
    }
    .feed-controls-order-options {
        position: relative;
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        padding-top: get-side($input-padding, top) / 2;
        padding-bottom: get-side($input-padding, bottom) / 2;
        border-radius: $input-border-radius;
        overflow: hidden;
        background-color: $input-background-color;
        transition: background-color .2s .2s ease,
                    box-shadow .2s ease;
        &:focus {
            outline: none;
        }
    }
    .feed-controls-order-label {
        display: flex;
        align-items: center;
        margin-top: 0;
        height: 0;
        opacity: 0;
        box-sizing: content-box;
        padding-left: get-side($input-padding, left);
        padding-right: get-side($input-padding, right);
        transition: height .2s .1s ease,
                    padding .2s .1s ease,
                    opacity .2s;
        &::after {
            content: '';
            display: inline-block;
            width: $font-size * 1.25;
            height: $font-size;
            flex-shrink: 0;
            margin-left: auto;
            background-size: $font-size * .75 $font-size * .75;
            background-position: right center;
            background-repeat: no-repeat;
            vertical-align: middle;
        }
    }
    .feed-controls-order-option:checked + .feed-controls-order-label {
        order: -1;
        height: $line-height;
        padding-top: get-side($input-padding, top) / 2;
        padding-bottom: get-side($input-padding, bottom) / 2;
        opacity: .6;
        &::after {
            @include sb-icon-arrow-down($text-color-regular);
        }
    }
    .feed-controls-heading:hover ~ .feed-controls-order-options {
        .feed-controls-order-option:checked + .feed-controls-order-label {
            opacity: 1;
        }
    }
    
    .feed-controls-order-options.feed-controls-open {
        z-index: 5;
        background-color: $input-background-color-focus;
        box-shadow: $input-shadow;
        transition: background-color .2s ease,
                    box-shadow .2s ease;
        .feed-controls-order-label {
            opacity: 1;
            padding-top: get-side($input-padding, top) / 2;
            padding-bottom: get-side($input-padding, bottom) / 2;
            height: $line-height;
            transition: height .2s ease,
                        padding .2s ease,
                        opacity .2s .2s;
        }
        .feed-controls-order-option:checked + .feed-controls-order-label {
            margin-top: 0;
            transition: none;
            &::after {
                @include sb-icon-arrow-up($text-color-regular);
            }
        }
        .feed-controls-order-label:hover {
            background-color: $input-background-color-hover;
        }

    }
    .feed-controls-order-options:focus-within {
        background-color: $input-background-color-focus;
        box-shadow: $input-shadow;
        transition: background-color .2s ease,
                    box-shadow .2s ease;
        .feed-controls-order-option:checked + .feed-controls-order-label {
            opacity: 1;
            transition: none;
        }
    }

    .feed-controls-visibility:checked + .feed-controls-heading:hover ~ .feed-controls-order-options {
        .feed-controls-order-option:checked + .feed-controls-order-label {
            // background-color: $input-background-color-hover;
        }
    }
}

@mixin feed(
    $outer-gutter: $gutter,
    $thumbnail-size: (
        small: $baseline * 3,
        smallplus: $baseline * 4,
        medium: $baseline * 6,
    ),
    $compact: false,

    $create-post-background-color: transparent,
    $create-post-text-color: $text-color-regular,
    $create-post-button-color: $anchor-color,
    $create-post-button-text-color: $white,

    $item-background-color: $off-white,
    $item-text-color: $text-color-regular,
    $item-button-color: $anchor-color,
    $item-button-text-color: $white
) {
    .controller.forum,
    .controller.create-post {    
        @include feed-input;
        .feed-item {
            @include feed-item($thumbnail-size, $outer-gutter, $compact);
        }
    }
    .controller.forum {
        @include feed-likes;
        @include feed-comments($compact);
        .message {
            color: $message-color;
            text-align: center;
            margin-top: $baseline * 1.5;
        }
        .feed {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }
        .feed-controls + .feed {
            margin-top: $baseline;
        }
        .feed-item {
            @include feed-item-post($compact: $compact);
            @include feed-item-color(
                $background-color: $item-background-color,
                $text-color: $item-text-color,
                $button-color: $item-button-color,
                $button-text-color: $item-button-text-color
            )
        }
        .button.show-more {
            margin-top: $baseline;
        }
        @include breakpoint(medium up) {
            .button.show-more {
                margin-top: 0;
            }      
        }
    }
    .controller.create-post {
        @include feed-item-color(
            $background-color: $create-post-background-color,
            $text-color: $create-post-text-color,
            $button-color: $create-post-button-color,
            $button-text-color: $create-post-button-text-color
        )
        .feed-item {
            border: 0;
        }
        @include breakpoint(small only) {
            .feed-item-thumbnail {
                display: none;
            }
        }
    }
}

@mixin feed-item(
    $thumbnail-size: (
        small: $baseline * 3,
        smallplus: $baseline * 4,
        medium: $baseline * 6,
    ),
    $outer-gutter: $gutter,
    $compact: false
) {
    @include grid;
    @include grid-template-columns(auto 1fr);
    position: relative;
    // display: flex;
    // max-width: 860px;
    padding: $baseline * 2 $outer-gutter;
    margin: 0 $outer-gutter * -1;
    border-top: 1px solid;
    text-align: left;
    button:focus {
        outline: none;
    }
    .text a,
    .feed-input a {
        text-decoration: underline;
    }
    @if $compact == false {    
        @include breakpoint(medium up) {
            border: none;
            border-radius: $baseline * 3;
            padding: $baseline * 1.5;
            margin: $baseline * 1.5 auto;
        }
    }
    .feed-item-thumbnail {
        @include grid-column(1);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        margin-right: $gutter;
        @if $compact == false {        
            @include breakpoint(medium up) {
                margin-right: $gutter * 2;
            }
            @include breakpoint(large up) {
                margin-right: $gutter * 3;
            }
        }
    }
    .feed-item-thumbnail,
    .feed-item-thumbnail .avatar {
        @include breakout($thumbnail-size) {
            width: $value;
        }
    }

    .feed-item-body {
        @include grid-column(2);
    }
    .feed-item-button {
        position: absolute;
        box-sizing: content-box;
        display: block;
        top: $baseline * 1.5;
        right: $baseline * 1;
        padding: $baseline / 2;
        text-align: center;
        @if $compact == false {        
            @include breakpoint(medium up) {
                top: calc(#{$baseline * 1.5} + #{$baseline / 2});
                right: $baseline * 1.5;
            }
        }
    }
}

@mixin feed-item-color(
    $background-color: $off-white,
    $text-color: $text-color-regular,
    $button-color: $anchor-color,
    $button-text-color: $white
) {
    background-color: $background-color;
    color: $text-color;
    a,
    button {
        color: rgba($text-color, .7);
    }
    a:hover,
    a:focus,
    button:hover,
    button:focus {
        color: $text-color;
    }
    .feed-item-meta {
        border-color: $text-color;
    }
    .comment-meta {
        border-color: rgba($text-color, .3);
    }
    .has-comments .comment-count-button {
        @include sb-icon-arrow-down(rgba($text-color, 0.7));
        &:hover {
            @include sb-icon-arrow-down($text-color);
        }
    }
    .comments .comments {
        border-color: rgba($text-color, .5);
    }

    &.comments-open .has-comments .comment-count-button {
        @include sb-icon-arrow-up($text-color);
        &:hover {
            @include sb-icon-arrow-up(rgba($text-color, 0.7));
        }
    }
    .like-button {
        @include icon-like(rgba($text-color, 0.7));
        &:hover,
        &:focus {
            @include icon-like(rgba($text-color, 0.9));
        }
        &.is-active {
            @include icon-like($text-color);
            &:hover,
            &:focus {
                @include icon-like(rgba($text-color, 0.9));
            }
        }
        &[disabled] {
            &:hover,
            &:focus {
                @include icon-like($text-color);
            }
        }
    }
    .feed-input + .feed-button,
    .comment-input + .comment-button {
        background-color: $button-color;
        border-color: $button-color;
        @include icon-send($button-text-color);
    }
}

@mixin feed-comments-compact {
    .comment .handle,
    .comment-tagline {
        display: block;
    }
    .comments .comments {
        margin-left: calc(#{$baseline * -3} - #{$gutter});
        padding-left: $gutter;
        border-left: 1px solid;
    }
}

@mixin feed-comments (
    $compact: false,
) {
    .feed-no-posts {
        margin-top: $baseline * 1.5;
    }
    .comment-date {
        line-height: 1;
        font-size: #{14/$em}rem;
    }
    .post-comment,
    .comment {
        position: relative;
    }
    .post-comment {
        margin-top: $baseline * 1.5;
    }
    .comment-reply .post-comment {
        // margin-bottom: $baseline;
    }
    .comment-list {
        @include unstyled-list;
        margin: 0;
    }
    .comment {
        @include grid;
        @include grid-template-columns(auto 1fr);
        margin-top: $baseline * 1.5;
        // margin-bottom: $baseline * 1.5;
        .comment-thumbnail {
            @include grid-column(1);
        }
        .comment-body {
            @include grid-column(2);
        }
    }
    .comment .handle,
    .comment-tagline {
        display: inline-block;    
        // @include breakpoint(smallplus down) {
        //     display: block;
        // }
    }
    .comment-text,
    .comment-input {
        & > div {
            margin-top: $baseline / 2;
        }
    }
    .comment .handle {
        margin-right: .5em;
    }
    .comment-tagline {
        opacity: .7;
    }
    .comment-text {
        margin-top: $baseline / 2;
        margin-bottom: $baseline;
        line-height: 1.25;
    }
    .comment-reply-button {
        line-height: inherit;
        font-weight: $bold;
    }
    .comment-meta {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: $baseline / 2;
        border-top: 1px solid;
        & > * {
            margin-right: $gutter;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .comment-list-root > .comment:first-child {
        margin-top: $baseline;
    }
    .comment-thumbnail {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        margin-right: $gutter;
    }
    .comment-thumbnail,
    .comment-thumbnail .avatar {
        width: $baseline * 3;
    }
    .comment-body {
        line-height: 1.2;
    }
    @if $compact == false {    
        @include breakpoint(smallplus down) {
            @include feed-comments-compact;
        }
    } @else {
        @include feed-comments-compact;
    }
}

@mixin feed-input {

    //comment inputs
    .feed-input-cont,
    .post-comment {
        display: flex;
        pointer-events: none;
        & > * {
            pointer-events: auto;
        }
    }

    .feed-input,
    .comment-input {
        z-index: 1;
        border: solid 1px;
        flex-grow: 1;
        //prevent overflow with wide words
        width: 0px;
        overflow: auto;
        border-radius: $baseline * 3 / 2;
        min-height: $baseline * 3;
        padding: ($baseline + $baseline-offset) / 2 $baseline ($baseline - $baseline-offset) / 2;
        line-height: calc(#{$baseline * 2} - 2px);
        transition: min-height .1s ease;
        &:focus,
        &:not(:empty) {
            min-height: 4.5rem;
        }
        @include breakpoint(small only) {
            padding-right: $baseline * 3.5;
        }
    }
    
    .feed-input {
        min-height: 4.5rem;
        @include breakpoint(smallplus up) {
            padding: ($baseline + $baseline-offset) $baseline * 1.5 ($baseline - $baseline-offset);
        }
        &:focus,
        &:not(:empty) {
            min-height: 9rem;
        }
    }
    .feed-input + .feed-button,
    .comment-input + .comment-button {
        position: relative;
        z-index: 1;
        display: block;
        flex-shrink: 0;
        align-self: flex-end;
        width: $baseline * 3;
        height: $baseline * 3;
        padding: 0;
        margin-left: $gutter;
        border-radius: 50%;
        // text-indent: 100%;
        // overflow: hidden;
        font-size: 0;
        color: transparent;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-position: 55% 50%;
        background-size: $baseline * 1.5 $baseline * 1.5;
        user-select: none;
        &::after {
            content: '';
            position: absolute;
            top: -#{$gutter};
            left: -#{$gutter};
            right: -#{$gutter};
            bottom: -#{$gutter};
        }
        @include breakpoint(small only) {
            margin-left: -#{$baseline * 3};
        }
        &:hover,
        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 $baseline * 3 rgba(0,0,0,0.15);
        }
        @media (hover: none) {
            &:hover {
                box-shadow: none;
            }
        }
        &:disabled {
            opacity: 0.6;
        }
        &:disabled:hover,
        &:disabled:focus {
            box-shadow: none;
        }
    }

    .feed-input:focus,
    .comment-input:focus {
        outline: none;
    }
    .feed-input:not(:focus):empty::after,
    .comment-input:not(:focus):empty::after {
        position: absolute;
        z-index: 2;
        content: attr(data-placeholder);
        display: block;
        color: inherit;  
        pointer-events: none;
    }
}

@mixin feed-likes() {
    .likes {
        display: flex;
        line-height: 1;
    }
    .like,
    .dislike {
        display: inline-flex;
        align-items: center;
        min-width: 2.5em;
    }
    .like-button {
        width: 1.5em;
        height: 1.5em;
        margin-top: -.5em;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &[disabled] {
            cursor: auto;
        }
    }
    .like-count {
        margin-left: .3em;
        &:empty {
            display: none;
        }
    }
    .comment {
        .likes {
            margin-top: $baseline;
        }
        .like,
        .dislike {
            min-width: 2em;
        }
        .like-button {
            width: 1em;
            height: 1em;
            margin-top: -.2em;
        }
    }
}

@mixin feed-item-post-compact {
    display: block;
    .feed-item-thumbnail {
        float: left;
    }
    .last-comment-button {
        display: none;
    }
    @supports (display: contents) {
        display: grid;
        grid-column-gap: $gutter;
        .feed-item-thumbnail {
            float: none;
        }
        .feed-item-thumbnail,
        .feed-item-body {
            display: contents;
        }
        .avatar {
            grid-column: 1;
            grid-row: 1 / span 3;
            align-self: center;
        }
        .feed-item-date,
        .feed-item-user-handle,
        .feed-item-user-tagline {
            grid-column: 2;
        }
        .feed-item-text,
        .feed-item-meta,
        .comments {
            grid-column: 1 / span 2;
        }
    }
}

@mixin feed-item-post(
    $compact: false
) {
    .read-more {
        line-height: inherit;
        font-weight: $bold;
        &::before {   
            content: '…';
            font-weight: $normal;
            margin-right: .3em;
        }
    }
    .feed-item-date,
    .feed-item-user-handle,
    .feed-item-user-tagline {
        line-height: 1;
        &:empty {
            display: none;
        }
    }
    .feed-item-user-handle,
    .handle {
        font-weight: $bold;
    }
    .feed-item-date {
        margin-top: $baseline / 2;
    }
    .feed-item-user-handle {
        margin-top: $baseline / 4;
        font-size: #{18/$em}rem;
    }
    .feed-item-user-tagline {
        margin-top: $baseline / 4;
    }
    @if $compact == false {
        @include breakpoint(medium up){
            .feed-item-user-handle,
            .feed-item-date {
                margin-top: $baseline;
                margin-bottom: $baseline / 2;
            }
            .feed-item-date {
                position: absolute;
                top: $baseline * 1.5;
                right: $baseline * 2.5;
            }
            .feed-item-button + .feed-item-date {
                right: $baseline * 3.5;
            }
        }
    }
    .feed-item-text,
    .feed-input-cont {
        margin: $baseline * 1.5 0;
    }
    .feed-item-text,
    .feed-input {
        line-height: 1.4;
        & > div {
            margin-top: $baseline;
        }
    }
    .feed-item-meta {
        display: flex;
        padding: $baseline * 1.5 0 $baseline / 2;
        border-top: 1px solid;
        font-size: 1rem;
        line-height: 1;
        .likes {
            margin-right: 1rem;
        }
        .feed-item-meta-comments {
            margin-left: auto;
            text-align: right;
        }
        .comment-count-button {
            margin-left: .75rem;
        }
        .has-comments .comment-count-button {
            background-size: 1rem 1rem;
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 1.5rem;
        }
    }
    @if $compact == false {         
        @include breakpoint(smallplus down) {
            display: block;
            .feed-item-thumbnail {
                float: left;
            }
            .last-comment-button {
                display: none;
            }
            @supports (display: contents) {
                display: grid;
                grid-column-gap: $gutter;
                .feed-item-thumbnail {
                    float: none;
                }
                .feed-item-thumbnail,
                .feed-item-body {
                    display: contents;
                }
                .avatar {
                    grid-column: 1;
                    grid-row: 1 / span 3;
                    align-self: center;
                }
                .feed-item-date,
                .feed-item-user-handle,
                .feed-item-user-tagline {
                    grid-column: 2;
                }
                .feed-item-text,
                .feed-item-meta,
                .comments {
                    grid-column: 1 / span 2;
                }
            }
        }
    } @else {
        @include feed-item-post-compact;
    }
}

//not ready yet!
@mixin feed-notifications(
) {
    //notifications
    .notification {
        .feed-item-thumbnail {
            background-position: center top;
            background-repeat: no-repeat;
            background-size: $gutter * 4 $gutter * 4;
            @include breakpoint(medium up) {
                background-size: $gutter * 8 $gutter * 8;
            }
        }
        .feed-item-title  {
            margin-top: $baseline;
        }
        .feed-item-text {
            margin-top: $baseline * 2;
            margin-bottom: $baseline;
            padding-right: $baseline;
        }
        .delete-feed-item {
            width: $baseline * 2;
            height: $baseline * 2;
            padding: 0;
            text-indent: 100%;
            overflow: hidden;
            white-space: nowrap;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            @include sb-icon-x($text-color-regular);
            &:hover {
                @include sb-icon-x($anchor-color-hover);
            }
        }
    }
    .notification-type-tip {
        background-color: $white;
        color: $text-color-regular;
        .feed-item-thumbnail {
            @include icon-feed-tip($secondary-color);
        }
    }
    .notification-type-inspiration,
    .notification-type-featured {   
        .delete-feed-item {
            @include sb-icon-x($white);
            &:hover {
                @include sb-icon-x($secondary-color);
            }
        }
        a {
            color: $anchor-color;
            &:hover,
            &:focus {
                color: $anchor-color-hover;
            }
        }
    }
    .notification-type-inspiration {
        background-color: $primary-color;
        color: $white;
        .feed-item-thumbnail {
            @include icon-feed-inspiration($white);
        }
    }
    .notification-type-certificate {
        background-color: $white;
        color: $text-color-regular;
        .feed-item-thumbnail {
            @include icon-feed-certificate($secondary-color);
        }
        form {
            max-width: none;
            padding: 0;
            background-color: transparent;
        }
    }
    .notification-type-featured {
        background-color: #666;    
        color: $primary-color;
        .feed-item-thumbnail {
            @include icon-feed-featured($primary-color);
        }
    }
    .notification-type-featured,
    .notification-type-inspiration {
        .feed-item-text {
            font-style: italic;
            font-size: #{20 / $em}rem;
        }
    }
    .feed-cta {
        display: flex;
        justify-content: space-around;

        .call-to-action {
            width: 100%;
            max-width: 860px;
        }
    }
}

