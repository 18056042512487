@mixin avatar(
    $width: $baseline * 8,
    $text-color: $text-color-white,
    $background-color: $primary-color,
    $text-size: 18px,
    $text-position: 30px
) {
    @include breakout($width) {
        width: $value;
    }
    .avatar-circle {
        position: relative;
        display: block;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
        img,
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg {
            fill: $text-color;
            background-color: $background-color;
        }
    }
    .avatar-initials-svg-text {
        font-size: $text-size;
        transform: translate(24px, #{$text-position});
    }
}
@keyframes avatar-load-pulse {
    from {
        background: rgba($white, .3);
    }
    to {
        background: rgba($white, .6);
    }
}
@mixin avatar-uploader(
    $size: $baseline * 12,
    $browse-background-color: rgba(0,0,0,0.2),
    $browse-background-color-hover: rgba($anchor-color, .5),
    $delete-color: $anchor-color,
    $delete-color-hover: $anchor-color-hover,
    $load-animation: .6s infinite alternate avatar-load-pulse
) {
    position: relative;
    width: $size;
    height: $size;
    .image-uploader-preview,
    .image-uploader-browse,
    .image-uploader-delete,
    .image-uploader-progress {
        position: absolute;
    }
    .avatar {
        width: 100%;
    }
    .image-uploader-preview,
    .image-uploader-browse,
    .image-uploader-progress {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }
    .image-uploader-browse {
        z-index: 2;
        display: flex;
        align-items: center;
        label {
            background-color: $browse-background-color;
            cursor: pointer;
            position: absolute;
            top: 70%;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-top: $baseline / 2;
            color: $white;
            text-shadow: 0 0 2px rgba(0,0,0,0.6);
            transition: background-color .2s;
            &:hover {
                background-color: $browse-background-color-hover;
            }
        }
        input[type="file"] {
            visibility: hidden;
            position: absolute;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }
    }
    .image-uploader-delete {
        z-index: 3;
        @include sb-icon-x($delete-color);
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 100%;
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        &:hover {
            @include sb-icon-x($delete-color-hover);
        }
    }
    .image-uploader-progress {
        display: flex;
        transform: rotate(-90deg);
    }
    .image-uploader-percenter {
        transition: width .3s linear;
        animation: $load-animation;
    }
}

