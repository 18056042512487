.header {
    position: relative;
    box-shadow: 0 1px 0 $light-gray;
    background-color: $white;
    .logo {
        display: flex;
        font-size: #{24/$em}rem;
        margin-bottom: #{2/$em}rem;
        color: $dark-gray;
        line-height: $baseline * 2;
        font-weight: $semibold;
        span {   
            display: block;
            margin-left: .5em;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .logo-all {
        span::first-letter {
            font-weight: $bold;
            color: $primary-color;
        }
        span.logo-mobile {
            display: none;
        }
        @include breakpoint(medium down) {
            span.logo-mobile {
                display: block;
                color: $primary-color;
            }
            span:not(.logo-mobile) {
                display: none;
            }
        }
    }
    .logo-full {
        span::first-letter {
            font-weight: $bold;
            color: $primary-color;
        }
        @include breakpoint(medium down) {
            span {
                font-size: 0;
                line-height: 0;
                margin: 0;
            }
            span::first-letter {
                vertical-align: middle;
                line-height: $baseline * 2;
                font-size: #{26/$em}rem;
            }
            span:last-child {
                display: none;
            }
        }
    }
    .logo-members {
        span:first-child {
            font-weight: $bold;
            color: $primary-color;
        }
        @include breakpoint(medium down) {
            span:last-child {
                display: none;
            }
        }
    }
    @include breakpoint(medium up) {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: $baseline * 5;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $off-white;
        }
    }
    @include breakpoint(large up) {
        &::before {
            top: $baseline * 6;
        }
    }
}

.header-inner {
    @include grid;
    @include grid-template-columns(auto 1fr auto auto);
    @include grid-template-rows($baseline * 5);
    position: relative;
    max-width: $site-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter * 2;
    .logo,
    .user-info,
    .quicklinks {
        @include grid-align-self(center);
    }
    .logo {
        @include grid-column(1);
    }
    .quicklinks,
    .user-info {
        position: relative;
        z-index: 20;
    }
    .quicklinks {
        @include grid-column(3);
    }
    .user-info {
        @include grid-column(4);
        margin-left: $gutter;
        margin-right: 36px + $gutter / 2;
    }
    .mobile-menu-expand {
        z-index: 2;
        position: absolute;
        right: $gutter;
        top: $baseline / 2;
    }
    @include breakpoint(medium up) {
        @include grid-template-rows($baseline * 5 auto);
        .search-cont {
            z-index: 21;
            position: absolute;
            right: $gutter * 2;
            top: $baseline;
            margin-top: #{3 / $em}rem;
        }
        .user-info {
            margin-right: 32px + $gutter;
        }
        .primary-navigation {
            @include grid-column(1, 4);
            @include grid-row(2);
        }
    }
    @include breakpoint(large up) {
        @include grid-template-rows($baseline * 6 auto);
        @include grid-template-columns(auto 1fr auto auto);
        .primary-navigation {
            @include grid-column(1, 5);
        }
        .search-cont {
            top: ($baseline / 2) + 1/$em;
            margin: 0;
        }
        .quicklinks,
        .user-info {
            @include grid-align-self(start);
        }
        .quicklinks {
            @include grid-column(3);
        }
        .user-info {
            @include grid-column(4);
            margin-left: $gutter * 1.5;
        }
    }
}
.primary-navigation {
    align-items: center;
    .nav-primary {
        font-size: #{18 / $em}rem;
        max-width: $site-width;
        padding: $baseline / 2 0;
    }
    .nav-primary a {
        display: block;
        padding-left: $gutter;
        padding-right: $gutter;
        line-height: $baseline * 2;
        color: $dark-gray;
        font-weight: $semibold;
        &:hover {
            color: $anchor-color-hover;
        }
    }
    .is-active > a {
        color: $primary-color;
        &:hover {
            color: $anchor-color-hover;
        }
    }
    .sayt-results {
        @include typography('text-medium');
    }
    @include breakpoint(smallplus down) {
        z-index: 20;
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        background-color: $off-white;
        box-shadow: 0 2px 2px rgba(0,0,0,0.15);
        .search-cont {
            font-size: #{20 / $em}rem;
            padding-left: $gutter;
            padding-right: $gutter;
            .search-input {
                border-width: 0px;
                border-bottom-width: 1px;
            }
            input {
                background-color: transparent;
            }
        }
        .nav-primary {
            a {
                display: block;
                padding: $baseline * 3 / 4 $gutter * 2;
            }
        }

        //visibility
        transform-origin: top;
        transition: transform .3s ease;
        transform: scaleY(0);
        .search-cont,
        .nav-primary a {
            transition: opacity .3s ease;
            opacity: 0;
        }
        .open & {
            transform: scaleY(1);
            .search-cont,
            .nav-primary a {
                transition: opacity .3s .2s ease;
                opacity: 1;
            }
        }
    }
    @include breakpoint(medium up) {
        .nav-primary {
            display: flex;
            flex-wrap: wrap;
            padding: $baseline 0;
            justify-content: space-around;
            font-size: #{14 / $em}rem;
            a {
                padding: 0 $baseline / 2;
            }
        }
    }
    @include breakpoint(large up) {
        .nav-primary {
            font-size: #{18 / $em}rem;
            justify-content: flex-end;
            a {
                margin-left: $baseline;
            }
        }
    }
}
.mobile-menu-expand {
    @include menu-button($primary-color);
    @include breakpoint(medium up) {
        display: none;
    }
    &.open {
        @include menu-button-open;
    }
}


.dropdown-menu {
    font-size: #{14/$em}rem;
    @include breakpoint(large up) {    
        .dropdown-menu-button,
        .link-button {
            font-size: #{18/$em}rem;
        }
    }
}

.dropdown-menu.user-info {
    @include dropdown-menu(
        $button-selector: '.dropdown-menu-button',
        $menu-selector: '.dropdown-menu-list',
        $min-width: 12rem,
        $button-color: $dark-gray,
        $button-color-hover: $gray,
        $menu-color: $white,
        $menu-color-hover: $off-white
    );
    border-radius: 0 0 .5rem .5rem;
    @include breakpoint(medium down) {
        @include dropdown-menu-compact(
            $button-selector: '.dropdown-menu-button',
            $menu-selector: '.dropdown-menu-list',
            $button-width: 2rem,
            $button-height: 2rem,
            $menu-width: 12rem
        );
        .dropdown-menu-button {
            @include sb-icon-user($white);    
        }
    }
}

.user-info .sign-in-button {
    @include dropdown-menu-button(
        $button-color: $dark-gray,
        $button-color-hover: $gray
    );
    @include sb-icon-arrow($white);
    padding-left: $baseline * 3;
    border-radius: 0 0 .5rem .5rem;
    border: 1px solid $dark-gray;
    min-width: 12rem;
    @include breakpoint(medium down) {
        @include dropdown-menu-compact-button(
            $button-width: 2rem,
            $button-height: 2rem
        );
        @include sb-icon-user($white);
        min-width: 0;
    }
    @include breakpoint(large up) {
        font-size: #{18/$em}rem;
    }
}

.item-group.quick-links {
    margin: 0;
}
.quick-links .dropdown-menu {
    @include dropdown-menu(
        $button-selector: '.dropdown-menu-button',
        $menu-selector: '.dropdown-menu-list',
        $min-width: 12rem,
        $button-color: $primary-color,
        $button-color-hover: $primary-color-light,
        $menu-color: $white,
        $menu-color-hover: $off-white
    );
    border-radius: 0 0 .5rem .5rem;
    @include breakpoint(medium down) {
        @include dropdown-menu-compact(
            $button-selector: '.link-button',
            $menu-selector: '.link-button-list',
            $button-width: 6rem,
            $button-height: 2rem,
            $menu-width: 12rem,
            $hide-text: false
        );
    }
}


