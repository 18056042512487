.footer {
    background-color: $white;
    box-shadow: 0 -1px 0 0 $light-gray;
    margin-top: $baseline * 3;
}
.footer-inner {
    max-width: $site-width;
    margin-left: auto;
    margin-right: auto;
    padding: $baseline * 2 $gutter * 2 $baseline * 4;
    text-align: center;
    & > * {
        display: block;
        margin-top: $baseline;
        &:first-child {
            margin-top: 0;
        }
    }
    .smallbox-credit {
        color: $text-color-light;
        a {
            color: inherit;
            &:hover,
            &:focus {
                color: $anchor-color-hover;
            }
        }
    }
    @include breakpoint(small down) {
        .footer-contact {
            a {
                display: inline-block;
                margin-left: .5rem;
                padding-left: .5rem;
                border-left: 1px solid $light-gray;
                &:first-child {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
        }
    }
    @include breakpoint(smallplus up) {
        @include grid;
        @include grid-template-columns(1fr auto #{$gutter * 2} auto 1fr);
        text-align: left;
        .smallbox-credit {
            @include grid-row(1);
            @include grid-column(2, 3);
        }
        .footer-contact-heading,
        .footer-contact {
            @include grid-row(2);
        }
        .footer-contact-heading {
            @include grid-column(2);
            @include grid-justify-self(end);
        }
        .footer-contact {
            @include grid-column(4);
            a {
                display: block;
            }
        }
    }
    @include breakpoint(large up) {
        @include grid-template-columns(1fr auto #{$gutter * 2} auto);
        .smallbox-credit,
        .footer-contact-heading,
        .footer-contact {
            @include grid-row(1);
            @include grid-justify-self(start);
            margin: 0;
        }
        .smallbox-credit {
            @include grid-column(1, 1);
            @include grid-align-self(end);
        }
        .footer-contact-heading {
            @include grid-column(2, 1);
        }
        .footer-contact {
            @include grid-column(4, 1);
        }
    }
}
