@mixin page-layout-timeline {
    @include breakpoint(large up) {
        .secondary {
            @include grid-column(1, 2, true);
        }
        .secondary + .primary {
            @include grid-column(3, 10, true);
        }
    }
    @include breakpoint(1600px up) {
        .secondary {
            @include grid-column(1, 1);
            @include grid-justify-self(center);
            width: auto;
        }
        .secondary + .primary {
            @include grid-column(1, 12, true);
        }
    }
}
@mixin timeline (
    $accent-colors: $primary-color $dark-gray,
    $item-background-color: $off-white,
    $line-color: $gray,
    $line-width: 4px,
    $dot-size: 1.2rem,
    $center-gutter: (
        small: $gutter * 2,
        medium: $gutter * 6,
        large: $gutter * 8,
    ),
) {
    @include grid;
    @include grid-template-columns(calc(#{get-breakpoint-value($center-gutter, small)}) 1fr);
    & > * {
        @include grid-column(1, 2);
        @include grid-row(2);
    }
    .show {
        @include grid-row(3);
        @include grid-column(2);
        @include typography('text-small');
        .button {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .item-wrapper {
        @include grid-row(1);
    }
    &::before {
        @include grid-row(2, 2);
        @include grid-column(1);
        @include grid-justify-self(start);
        content: '';
        display: block;
        width: $line-width;
        height: 100%;
        background-color: $line-color;
    }
    .item {
        @include grid;
        @include grid-template-rows(auto auto auto);
        @include grid-template-columns(get-breakpoint-value($center-gutter, small) 1fr);
        margin: $baseline * 2 0;
        padding: 0;
        //chrome and safari way to break long words
        word-break: break-word;
        //the newest w3c spec to properly break long words (implemented in FF)
        overflow-wrap: anywhere; 
        &::before {
            @include grid-row(1, 3);
            @include grid-column(2);
            content: '';
            display: block;
            border-left: $line-width solid;
            background-color: $item-background-color;
        }
        & > * {
            @include grid-column(2);
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
        }
        .date-posted {
            @include grid-row(1);
            margin-top: $baseline;
        }
        .title {
            @include grid-row(2);
            position: relative;
            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 100%;
                bottom: 0;
                width: 1.5rem;
                height: 1.5rem;
                border: .75rem solid transparent;
                border-right-color: currentColor;
            }
        }
        .text {
            @include grid-row(3);
            margin-top: $baseline;
            margin-bottom: $baseline;
        }
    }
    @include breakpoint(large up) {
        @include grid-template-columns(1fr get-breakpoint-value($center-gutter, medium) 3fr);
        & > * {
            @include grid-column(1, 3);
        }
        &::before {
            @include grid-column(2);
            @include grid-justify-self(center);
        }
        .show {
            @include grid-column(3);
        }
        .item {
            @include grid-template-rows(auto auto);
            @include grid-template-columns(1fr get-breakpoint-value($center-gutter, medium) 3fr);
            &::after {
                @include grid-row(1);
                @include grid-column(2);
                @include grid-align-self(end);
                @include grid-justify-self(center);
                content: '';
                display: block;
                width: $dot-size;
                height: $dot-size;
                margin-bottom: calc((1.5rem - #{$dot-size}) / 2);
                border-radius: 50%;
            }
            &::before,
            .title,
            .text {
                @include grid-column(3);
            }
            .date-posted {
                @include grid-column(1, 2);
                @include grid-align-self(end);
                padding: 0;
                margin: 0;
                display: flex;
                height: 1.5rem;
                line-height: 1.5rem;
                white-space: nowrap;
                &::after {
                    content: '';
                    display: block;
                    flex-grow: 1;
                    flex-basis: 0%;
                    align-self: center;
                    height: 3px;
                    padding-left: 1rem;
                    margin-right: get-breakpoint-value($center-gutter, medium);
                    background-color: currentColor;
                    background-clip: content-box;
                }
            }
            &::before {
                @include grid-row(1, 2);
            }
            .title {
                @include grid-row(1);
                margin-top: $baseline * 1.5;
            }
            .text {
                @include grid-row(2);
                margin-bottom: $baseline * 1.5;
            }
        }
    }
    @include breakpoint(1600px up) {
        @include grid-template-columns(1fr get-breakpoint-value($center-gutter, large) 1fr);
        &::before {
            @include grid-row(2);
        }
        .show {
            @include grid-column(1, 3);
            @include grid-justify-self(center);
            .button {
                min-width: 9rem;
            }
        }
        .item-wrapper {
            text-align: center;
        }
        .item {
            @include grid-template-columns(1fr get-breakpoint-value($center-gutter, large) 1fr);
            .date-posted {
                @include grid-column(1, 1);
                @include grid-justify-self(end);
                &::after {
                    flex-grow: 0;
                    flex-basis: 6rem;
                    width: 4rem;
                    margin: 0;
                }
            }
            .title {
                margin-top: $baseline * 2;
            }
            &:nth-child(even) {
                &::before,
                .title,
                .text {
                    @include grid-column(1);
                }
                &::before {
                    border-left: none;
                    border-right: $line-width solid;
                }
                .title::before {
                    right: auto;
                    left: 100%;
                    border-color: transparent;
                    border-left-color: currentColor;
                }
                .date-posted {
                    @include grid-column(3);
                    @include grid-justify-self(start);
                    flex-direction: row-reverse;
                    &::after {
                        padding-left: 0;
                        padding-right: 1rem;
                    }
                }
            }
        }
    }
    @for $i from 1 through length($accent-colors) {
        $accent-color: nth($accent-colors, $i);
        .item:nth-child(#{$i}n) {
            &::before {
                border-color: $accent-color;
            }
            &::after {
                background-color: $accent-color;
            }
            .title,
            .date-posted {
                color: $accent-color;
            }
        }
    }
}