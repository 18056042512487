@import 'forms:mixins';
.content {
    .input {
        @include input-base(
            $vertical-margin: $baseline * 2,
            $input-border-radius: .5rem
        );
        @include input-colors(
            $input-background: $white,
            $input-border: $light-gray
        );
        @include input-label-absolute(
            $outer-background-color: $off-white
        );
        // input,
        // textarea {
        //     background-color: $white;
        //     border-color: $light-gray;
        // }
    }
    .input.switch {
        @include input-reset;
        @include input-switch(
            $unchecked-color: $light-gray
        );
        label {
            font-size: 1rem;
        }
    }
    .input ~ .button {
        margin-top: $baseline * 2;
    }
    .button:disabled {
        opacity: 1;
    }
    .manage-subscriptions {
        .sb-error-title {
            @include typography('header-c');
        }
        .sb-error-message {        
            @include input-error;
            margin-top: $baseline;
            pre {
                margin: 0;
                overflow: visible;
            }
        }
        .subscription-section-head {
            display: none;
        }
    }
    .sb-section {
        @include input-list-table(
            $vertical-margin: $baseline * 2,
            $border: 1px solid $light-gray,
            $border-radius: .5rem,
            $header-background-color: $white,
            $body-background-color: #eae7e2,
            $body-background-odd-color: $off-white,
            $label-options: (
                outer-background-color: $off-white
            )
        );
        @include breakpoint(500px down) {
            @include input-list-table-stack;
        }
    }
}

.button-cont {
    text-align: center;
}


//this class is used on admin right now
// .text-icon-button,
// a.text-icon-button {
//     @include text-icon-button;
// }
.button,
a.button {
    @include button;
}
.button[type="submit"],
.button.submit {
    color: $text-color-white;
    border-color: $anchor-color;
    background-color: $anchor-color;
    &:hover,
    &:focus {
       border-color: $anchor-color-hover;
       background-color: $anchor-color-hover; 
    }
}
.button.text-only {
    border-radius: 0;
    border-color: transparent;
    background-color: transparent;
    color: $text-color-regular;
    &:hover,
    &:focus {
        border-color: transparent;
        background-color: transparent;
        color: $anchor-color-hover;
    }
}

input,
textarea {
    border: 1px solid $light-gray;
    outline-offset: 0;
    padding: $baseline / 2 .5em;
    &:focus,
    &:active {
        outline: none;
        border-color: $anchor-color;
    }
}
textarea {
    min-height: $baseline * 3;
}
