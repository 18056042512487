//search form
@include search-form(
    $text-padding: .75rem
);
@include breakpoint(medium up) {
    @include search-form-collapse(
        $primary-color: $text-color-regular,
        $secondary-color: $anchor-color-hover,
        $height: 32px,
        $width: 400px,
        $text-padding: .4rem,
        $icon-size: 30px
    );
}
.search-input {
    color: $text-color-regular;
    border-color: transparent;
    border-bottom-color: $text-color-regular;
    &:focus {
        border-color: transparent;
        border-bottom-color: $text-color-regular;
    }
}
.search-cont .sayt-cont {
    height: auto;
    max-height: 60vh;
    background-color: $white;
    border: 1px solid $primary-color;
    // background-color: lighten($primary-color, 45%);
    .search-result a,
    .sayt-no-results {
        color: $text-color-regular;
    }
    .search-result a:hover,
    .sayt-selected a {
        background-color: $off-white;
    }
}
.item-group.search-results {
    @include search-results;
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;
    .url a {
        text-decoration: underline;
    }
    .paging {
        @include search-paging-buttons;
    }
}