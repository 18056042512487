@import '@depot/typography';

$baseline: .75rem !default;
$gutter: 12px !default;

//put in containing display point
@mixin poll-page-layout($results-column-width: 280px, $gutter: $gutter * 4) {
    @include breakpoint(medium up) {
        @include grid;
        @include grid-template-columns(1fr $results-column-width);
        .poll-question,
        .poll-answers {
            @include grid-column(1);
            @include grid-row(2);
            margin-right: $gutter;
        }
        .poll-list {
            @include grid-column(2);
            @include grid-row(2);
        }
    }
}


@mixin poll-question(
    $baseline: $baseline,
    $gutter: $baseline,
    $background-color: $off-white,
    $input-color: $text-color-regular,
    $header-border-color: $text-color-regular
) {
    .poll-title {
        // background-color: $primary-color;
        // color: $text-color-white;
        @include typography('header-c');
        border-bottom: 1px solid $header-border-color;
        padding: $baseline $gutter;
    }
    .poll-options {
        @include unstyled-list;
        // margin-bottom: $baseline;
    }
    .poll-option {
        // display: flex;
        // align-items: center;
        // background-color: #f3f2f0;
        &:nth-child(even) {
            background-color: $background-color;
        }
        [type="radio"],
        label {
            cursor: pointer;
        }
        [type="radio"] {
            width: 0px;
            height: 0px;
            overflow: hidden;
            position: absolute;
            margin: 0;
            opacity: 0;
        }
        label {
            padding: $baseline $gutter;
            min-height: $baseline * 4;
            line-height: 1.4;
        }
        [type="radio"] + label {
            display: flex;
            align-items: center;
            margin: 0;
            &::before {
                content: '';
                display: block;
                flex-shrink: 0;
                width: 1.4em;
                height: 1.4em;
                margin-right: .5em;
                border: 2px solid $input-color;
                border-radius: 50%;
                padding: .3em;
                background-clip: content-box;
            }
            &:hover,
            &:focus {
                color: $anchor-color;
                &::before {
                    border-color: $anchor-color;
                }
            }
        }
        [type='radio']:checked + label {
            &::before {
                background-color: $input-color;
            }
        }
    }
    .poll-options ~ .button {
        margin-top: $baseline;
        margin-right: $gutter;
        @include breakpoint(smallplus up) {
            min-width: 8rem;
        }
    }
}

@mixin poll-answers(
    $baseline: $baseline,
    $gutter: $baseline,
    $figure-height: $baseline * 3,
    $color: $primary-color,
    $background-color: $white
) {
    .poll-answer {
        margin-top: $baseline;
        &:first-child {
            margin-top: 0;
        }
    }
    .poll-percentage {
        display: flex;
        border: solid 1px;
        margin-top: $baseline / 2;
        height: $figure-height;
        background-color: $background-color;
    }

    .poll-percenter {
        background: $color;
        flex-shrink: 0;
    }
    .poll-value {
        align-self: center;
        display: flex;
        justify-content: flex-end;
        min-width: 0px;
    }
    .poll-value-inner {
        white-space: nowrap;
        margin: 0 $gutter;
        padding: 0 $gutter;
        background-color: rgba($background-color, .85);
        border-radius: $baseline * 2;
    }
    .poll-total {
        display: block;
        margin-top: $baseline;
    }
}

@mixin poll-list(
    $header-border-color: $text-color-regular,
    $item-border-color: $text-color-light
) {
    .poll-title {
        @include typography('header-c');
        border-bottom: 1px solid $header-border-color;
        padding: $baseline 0;
    }
    .item {
        margin-top: $baseline / 2;
        border-top: 1px solid $text-color-light;
        &:first-child {
            border-top: none;
        }
    }
    .item a {
        display: block;
        padding-top: $baseline / 2;
    }
    .show {
        margin-top: $baseline;
    }
}