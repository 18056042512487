@mixin page-layout-base {
    .secondary {
        display: none;
    }
    @include breakpoint(medium up) {
        @include grid;
        @include grid14;
        // @include grid-template-columns(1fr #{$gutter * 2} 3fr);
        & > * {
            @include grid-justify-self(stretch);
        }
        .header-banner {
            @include grid-row(1);
            @include grid-column(1, 14);
        }
        .secondary,
        .primary {
            @include grid-row(2);
        }
        .secondary {
            display: block;
            @include grid-column(1, 3, true);
        }
        .secondary:empty + .primary,
        .primary {
            @include grid-column(1, 12, true);
        }
        .secondary + .primary {
            @include grid-column(4, 9, true);
        }
    }
    @include breakpoint(large up) {
        // @include grid-template-columns(1fr #{$gutter * 2} 4fr);
    }
}
@mixin page-layout-home {
    display: flex;
    flex-direction: column;
    .secondary {
        display: block;
        order: 2;
    }
    @include breakpoint(medium up) {
        @include grid;
        @include grid14;
        .primary {
            @include grid-column(1, 6, true);
            display: flex;
            flex-direction: column;
        }
        .secondary {
            @include grid-column(7, 6, true);
        }
    }
    @include breakpoint(large up) {
        .primary {
            @include grid-column(1, 7, true);
        }
        .secondary {
            @include grid-column(8, 5, true);
        }
    }
}
@mixin page-layout-minimal {
    .primary {
        @include grid-column(1, 12, true);
        text-align: center;
        max-width: $content-width;
        margin-left: auto;
        margin-right: auto;
    }
    .item-group {
        margin-left: auto;
        margin-right: auto;
    }
    @include breakpoint(medium up) {
        .primary {
            @include grid-column(1, 12, true);
        }
    }
}
@mixin page-layout-profile {
    .primary {
        // max-width: $content-width;
        // margin-left: auto;
        // margin-right: auto;
        @include grid-column(1, 12, true);
        .avatar-uploader {
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(medium up) {
            @include grid;
            @include grid-template-columns(auto 1fr);
            .page {
                @include grid-row(1);
                @include grid-column(2);
            }
            .my-avatar {
                @include grid-row(2);
                @include grid-column(1);
                margin-right: $gutter * 2;
            }
            .my-profile {
                @include grid-row(2);
                @include grid-column(2);
            }
        }
        @include breakpoint(medium up) {
            .my-avatar {
                margin-right: $gutter * 4;
            }
        }
    }
}

