//Callout-like navigation panes with a background image
//Guts is the space between items, only single values allowed (no responsive maps)
//Accepts a default image that can be overridden by the cms
@mixin overview-grid(
    $guts: $baseline, 
    $image: none, 
    $baseline: $baseline,
    $blend-mode: multiply,
    $tile-color: $primary-color, 
    $tile-color-hover: rgba($secondary-color, .7), 
    $background-color: #fff,
    $items-min-height: $baseline * 32,
    $item-min-height: (
        small: $baseline * 6,
        medium: $baseline * 12
    ),
    $tile-shadow: none,
    $tile-shadow-hover: none
) {
    .navigation-overview-list {
        position: relative;
        display: flex;
    }
    .items {
        margin-left: $guts / -2;
        margin-right: $guts / -2;
        margin-bottom: $guts * -1;
        flex-grow: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background-color: $tile-color;
        background-blend-mode: $blend-mode;
        background-clip: content-box;
        background-size: cover;
        background-position: center;
        background-image: $image;
        @include breakout($items-min-height) {
            min-height: $value;
        }
    }
    .item {
        @include breakout($item-min-height) {
            min-height: $value;
        }
        @include breakpoint(small up) {
            flex-basis: calc(100% - #{$guts});
            .text-group {
                max-width: 100%;
            }
        }
        @include breakpoint(smallplus up) {
            flex-basis: calc(#{100% / 2} - #{$guts});
            &:nth-child(2n+1):last-child {
                .text-group {
                    max-width: 50%;
                }
            }
        }
        @include breakpoint(medium up) {
            flex-basis: calc(#{100% / 3} - #{$guts});
            &:nth-child(2n+1):last-child {
                .text-group {
                    max-width: none;
                }
            }
            &:nth-child(3n+1):last-child {
                .text-group {
                    max-width: 33%;
                }
            }
            &:nth-child(3n+2):last-child,
            &:nth-child(3n+1):nth-last-child(0n + 2) {
                .text-group {
                    max-width: 75%;
                }
            }
        }
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        margin-left: $guts / 2;
        margin-right: $guts / 2;
        margin-bottom: $guts;
        background-color: rgba($tile-color, .2);
        color: $white;
        outline: $guts solid $background-color;
        transition: background-color 400ms cubic-bezier(0.5, 0.5, 0, 1);
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .text-group {
            z-index: 2;
            position: relative;
            max-height: 100%;
            overflow: hidden;
            text-align: center;
            margin: auto;
        }
        .title {
            @extend %header-d !optional;
        }
        .title, .summary {
            margin: $baseline;
        }
        .summary {
            font-size: inherit;
        }
        &::before {
            content: '';
            z-index: 1;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            box-shadow: $tile-shadow;
            transition: box-shadow 400ms cubic-bezier(0.5, 0.5, 0, 1),
                        background-color 400ms cubic-bezier(0.5, 0.5, 0, 1);
            background-color: $tile-color;
            @if $blend-mode != normal {            
                background-color: rgba($tile-color, .6);
                @supports (background-blend-mode: multiply) {
                    background-color: transparent;
                }
            }
        }
    }
    .item:hover {
        @if $blend-mode != normal {            
            background-color: $tile-color-hover;
        }
        .head::before {
            @if $blend-mode == normal {
                background-color: $tile-color-hover;
            }
            box-shadow: $tile-shadow-hover;
        }
    }
}
