@mixin banner-image() {
    .image {
        @include object-fit;
        display: flex;
        height: 15 * $baseline;
        @include breakpoint(medium up) {
            height: 20 * $baseline;
        }
        @include breakpoint(large up) {
            height: 25 * $baseline;
        }
        @include breakpoint(xlarge up) {
            height: 30 * $baseline;
        }
    }
}
@mixin banner-slideshow() {
    // @include slideshow;
    @include banner-image;
    .head {
        position: relative;
    }
    .text-group {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: $site-width;
        padding-left: $gutter * 2;
        padding-right: $gutter * 2;
        .title {
            @include typography('text-small');
            font-weight: $normal;
            color: $text-color-white;
            padding: .5rem .75rem;
            border-radius: .5rem .5rem 0 0;
            background-color: rgba($black, .8);
            @include breakpoint(large up) {
                margin-right: $gutter + 32px;
            }
        }
    }
}
@mixin article-list() {
    @include article-icons($color: $primary-color, $hover-color: $primary-color-light);
    @include basic-article-list(
        $image-size: (
            small: $baseline * 2,
            smallplus: $baseline * 3,
            large: $baseline * 5
        ),
        $compact-breakpoint: smallplus
    );
    .item {
        border-bottom: 1px solid $gray;
        @include breakpoint(medium up) {
            // padding-left: $baseline * 1.5;
            // padding-right: $baseline * 1.5;
        }
    }
    svg {
        fill: $dark-gray;
    }
    .head-link {
        color: inherit;
    }
    .head {
        .title {
            @include typography('header-c');
        }
        .summary {
            @include breakpoint(medium up) {
                @include typography('text-large');
            }
        }
        .date-posted {
        }
    }
    .text-group {
        display: flex;
        flex-direction: column;
        .date-posted {
            display: block;
            order: -1;
            margin-top: $baseline / -2;
        }
    }
}
@mixin dropdown-menu-button(
    $button-color: $anchor-color,
    $button-color-hover: $anchor-color-hover,
    $icon-size: 1rem
) {
    display: block;
    background-color: $button-color;
    width: 100%;
    padding: $baseline;
    padding-right: $baseline * 2 + 1rem;
    color: $white;
    line-height: 1;
    text-align: left;
    background-size: $icon-size $icon-size;
    background-repeat: no-repeat;
    background-position: right $baseline center;
    &:hover {
        background-color: $button-color-hover;
    }
}
@mixin dropdown-menu(
    $button-selector,
    $menu-selector,
    $min-width,
    $button-color: $anchor-color,
    $button-color-hover: $anchor-color-hover,
    $menu-color: $white,
    $menu-color-hover: $off-white,
    $icon-size: 1rem
) {
    position: relative;
    overflow: hidden;
    border: 1px solid $button-color;
    min-width: $min-width;

    #{$button-selector} {
        // background-color: $button-color;
        // width: 100%;
        // padding: $baseline;
        // padding-right: $baseline * 2 + 1rem;
        // color: $white;
        // text-align: left;
        // @include sb-icon-arrow-down($white);
        // background-size: $icon-size $icon-size;
        // background-repeat: no-repeat;
        // background-position: right $baseline center;
        // &:hover {
        //     background-color: $button-color-hover;
        // }
        @include dropdown-menu-button(
            $button-color: $button-color,
            $button-color-hover: $button-color-hover,
            $icon-size: $icon-size
        );
        @include sb-icon-arrow-down($white);
    }
    &.opened #{$button-selector} {
        @include sb-icon-arrow-up($white);
    }

    #{$menu-selector} {
        background-color: $menu-color;
        padding-top: $baseline / 2;
        padding-bottom: $baseline / 2;
        a {
            display: block;
            padding: $baseline / 2 .5rem;
            padding: .5rem .75rem;
            line-height: 1;
            color: inherit;
            opacity: 0;
            transition: opacity .2s;
            &:hover,
            &:focus {
                background-color: $menu-color-hover;
            }
        }
        @include breakpoint(large up) {

        }    
    }
    &.opened #{$menu-selector} {
        a {
            opacity: 1;
            transition: opacity .2s .2s;
        }
    }
}

@mixin dropdown-menu-compact-button(
    $button-width,
    $button-height,
    $hide-text: true,
    $icon-size: $button-height / 2
) {
    width: $button-width;
    height: $button-height;
    padding: .5rem .75rem;
    background-size: $icon-size $icon-size;
    background-position: right ($button-height - $icon-size) / 2 center;
    border-radius: $button-height / 2;
    transition: width .2s ease,
                border-radius .1s .1s ease;
    @if ($hide-text) {
        padding: 0;
        text-indent: 100%;
        overflow: hidden;
        color: transparent;
        white-space: nowrap;
    } @else {
        background-image: none;
    }
}

@mixin dropdown-menu-compact(
    $button-selector,
    $menu-selector,
    $button-width,
    $button-height,
    $menu-width,
    $hide-text: true,
    $icon-size: $button-height / 2
) {
    height: $button-height;
    min-width: 0;
    overflow: visible;
    border: none;
    border-radius: 0;
    width: $button-width;

    #{$button-selector} {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        @include dropdown-menu-compact-button(
            $button-width,
            $button-height,
            $hide-text,
            $icon-size
        )
        // width: $button-width;
        // height: $button-height;
        // padding: .5rem .75rem;
        // background-size: $icon-size $icon-size;
        // background-position: right ($button-height - $icon-size) / 2 center;
        // border-radius: $button-height / 2;
        // transition: width .2s ease,
        //             border-radius .1s .1s ease;
        // @if ($hide-text) {
        //     padding: 0;
        //     text-indent: 100%;
        //     overflow: hidden;
        //     color: transparent;
        //     white-space: nowrap;
        // } @else {
        //     background-image: none;
        // }
    }
    &.opened #{$button-selector} {
        width: $menu-width;
        border-radius: 0;
        transition: width .2s ease,
                    border-radius .1s ease;
    }

    #{$menu-selector} {
        position: absolute;
        top: 0;
        right: 0;
        border-top: $button-height solid transparent;
        background-clip: padding-box;
        width: $menu-width;
        transition: box-shadow .1s ease;
        padding-top: .25rem;
        padding-bottom: .25rem;
        a {
            padding: .5rem .75rem;
        }
    }
    &.opened #{$menu-selector} {
        transition: box-shadow .2s .2s ease;
        box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    }
}
@mixin login-form() {
    max-width: 360px;
    .button[type="submit"] {
        min-width: 12rem;
    }
    .message {
        margin-bottom: $baseline * 2;
    }
    .input-error {
        text-align: left;
    }
    .button-cont {
        text-align: inherit;
        margin-top: $baseline * 2;
    }
    .aside-text {
        margin-top: $baseline;
    }
}
@mixin secondary-navigation() {
    .sub-menu {
        padding-left: $baseline * 1.5;
    }
    a {
        display: block;
        padding-top: $baseline / 2;
        padding-bottom: $baseline / 2;
        padding-left: calc(#{$baseline * 1.5} - 1px);
        border-left: 1px solid transparent;
        color: $dark-gray;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .is-active > a {
        border-left-color: $primary-color;
        color: $primary-color;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
}
@mixin article-page {
    & > * {
        margin-top: $baseline * 2;
        &:first-child {
            margin-top: 0;
        }
    }
    .date-posted {
        display: block;
        margin-top: $baseline;
    }
    .description {
        @include typography('text-large');
    }
}
@mixin member-list {
    .items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;
        margin-right: -$gutter;
        margin-top: $baseline * -2;
        @include breakpoint(medium up) {
            margin-top: $baseline * -3;
        }
    }
    .item {
        flex-basis: 50%;
        width: 50%;
        padding-left: $gutter;
        padding-right: $gutter;
        margin-top: $baseline * 2;
        @include breakpoint(smallplus up) {
            flex-basis: percentage(1 / 3);
            width: percentage(1 / 3);
        }
        @include breakpoint(medium up) {
            margin-top: $baseline * 3;
            flex-basis: percentage(1 / 4);
            width: percentage(1 / 4);
        }
        @include breakpoint(large up) {
            flex-basis: percentage(1 / 5);
            width: percentage(1 / 5);
        }
    }
    .name {
        margin-top: $baseline * 1.5;
        text-align: center;
        line-height: 1;
        margin-left: $gutter / -2;
        margin-right: $gutter / -2;
        font-size: #{18/$em}rem;
    }
    .avatar {
        width: 100%;
        transition: filter .2s;
    }
    a.item:hover,
    a.item:focus {
        .avatar {
            filter: brightness(110%);
        }
    }
}
@mixin bytebloc {
    display: flex;
    flex-direction: column;
    iframe {
        flex-grow: 1;
        border: 0;
        width: 100%;
        min-height: #{320/$em}rem;
        @include breakpoint(medium up) {
            min-height: #{640/$em}rem;
        }
    }
}
@mixin profile-form {
    margin-top: $baseline;
    // .input:first-child {
    //     margin-top: 0;
    // }
    .my-profile-fields {
    }
    @include breakpoint(large up) {
        .my-profile-fields {
            display: flex;
            & > * {
                flex-basis: 50%;
            }
        }
        .profile-form-left {
            margin-right: $gutter;
        }
        .profile-form-right {
            margin-left: $gutter;
        }
    }
}
@mixin member-details-main {
    @include grid;
    @include grid-template-columns(auto 1fr);
    .title {
        @include grid-row(1);
        @include grid-column(2);
        @include grid-align-self(center);
        margin-left: $gutter * 1.5;
    }
    .avatar {
        @include grid-row(1);
        @include grid-column(1);
        width: $baseline * 4;
        margin: 0;
        @include breakpoint(smallplus up) {
            width: $baseline * 6;
        }
        @include breakpoint(medium up) {
            width: $baseline * 12;
        }
    }
    .brief {
        @include grid-row(2);
        @include grid-column(1, 2);
        font-size: #{18 / $em}rem;
        line-height: $baseline * 2.5;
    }
    @include breakpoint(smallplus down) {
        .title {
            padding-bottom: 0;
            border: none;
        }
        .brief {
            padding-top: $baseline;
            border-top: 1px solid $gray;
        }
    }
    @include breakpoint(medium up) {
        .title {
            @include grid-column(1, 2);
            margin-left: 0;
        }
        .avatar {
            @include grid-row(2);
            @include grid-column(1);
            margin-top: $baseline * 2;
        }
        .brief {
            @include grid-column(2, 1);
            margin-left: $gutter * 4;
            margin-top: $baseline * 2;
        }
    }
    & > * {
        &:first-child {
            margin-top: 0;
        }
    }
}
@mixin member-details {
    margin-top: 0;
    .member-details-left,
    .member-details-right {
        margin: 0;
    }
    @include breakpoint(medium up) {
        @include grid;
        @include subgrid;
        margin-left: $gutter * -2;
        margin-right: $gutter * -2;
        & > * {
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
        }
        .member-details-left {
            @include grid-row(1);
            @include grid-column(1, 7);
        }
        .member-details-right {
            @include grid-row(1);
            @include grid-column(8, 5);
        }
    }
    dl {
        margin: 0;
    }
    dt {
        margin-top: $baseline * 1.5;
        font-weight: $bold;
        font-size: #{14/$em}rem;
        line-height: $baseline * 2;
    }
    dd {
        margin: 0;
    }
    .contact {
        dl {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: dense;
            grid-column-gap: $gutter;
            margin-top: $baseline / 2;
        }
        dt:nth-of-type(odd),
        dd:nth-of-type(odd) {
            grid-column: 1;
            &:last-child {
                grid-column-end: span 2;
            }
        }
        dt:nth-of-type(even),
        dd:nth-of-type(even) {
            grid-column: 2;
        }
        dt {
            margin-top: $baseline;
        }
        // dt:first-child {
        //     margin-top: 0;
        // }
        @supports (break-before: avoid) {
            // dl {
            //     column-count: 2;
            // }
            // dd {
            //     break-before: avoid;
            //     break-inside: avoid;
            // }
        }
    }
    .item-group {
        & > * {
            margin-top: $baseline * 1.5;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
