@import '@depot/grid';
@import '@depot/utility';
@import '@depot/breakpoint';
@import '@foundation/util/value';
@import 'site:variables';
@import 'site:icon-mixins';
@import 'avatars:mixins';
@import 'mixins';
@import 'icons';

@include feed(
    $outer-gutter: $gutter * 2,
    $item-background-color: $white
);

.feed-controls {
    @include feed-controls($input-background-color: $light-gray);
}

.controller.forum-compact {
    @include feed-likes;
    @include feed-comments($compact: true);
    @include feed-input;
    .feed-item {
        @include feed-item(
            $outer-gutter: $gutter * 2,
            $thumbnail-size: (
                small: $baseline * 3,
                smallplus: $baseline * 4
            ), 
            $compact: true
        );
        @include feed-item-post($compact: true);
        @include feed-item-color(
            $background-color: $white
        );
        @include breakpoint(medium up) {
            margin: $baseline * 2 0 0;
            border-radius: $baseline * 2;
            border: none;
        }
    }
    .show-more.button {
        margin-top: $baseline * 2;
    }
}
.controller.forum,
.controller.create-post,
.controller.forum-compact {
    .feed-item {
        border-color: $light-gray;
    }
    .feed-input,
    .comment-input {
        border-color: $light-gray;
        background-color: $light-gray;
    }
}
