.item-group,
.sb-display-point-first {
    margin-top: $baseline * 3;
}
.item-group {
    & > header .title,
    & > .title {
        padding-bottom: $baseline;
        border-bottom: 1px solid $gray;
    }
    & > * {
        margin-top: $baseline;
        &:first-child {
            margin-top: 0;
        }
    }
    header:empty {
        display: none;
        & + * {
            margin-top: 0;
        }
    }
}
.item-group.page {
    // max-width: $content-width;
    & > header > .title:not(.sb-inline-editor),
    & > .title:not(.sb-inline-editor) {
        display: inline-block;
        padding-right: $baseline;
        margin-right: -$baseline;
    }
}
.item-group.banner-image {
    @include banner-image;
}
.item-group.slideshow {
    @include banner-slideshow;
}
.item-group.nav-overview {
    @include overview-grid(
        $background-color: $off-white,
        $tile-color: $light-gray, 
        $tile-color-hover: rgba($primary-color, .6),
        $items-min-height: $baseline * 16,
        $item-min-height: (
            small: $baseline * 8,
            medium: $baseline * 16
        )
    );
    .items {
        background-blend-mode: overlay;
    }
    .item {
        // border: 1px solid $gray;
        box-shadow: inset 0 0 0 1px $gray;
        color: $text-color-dark;
    }
}

.item-group.article-list {
    @include article-list;
}
.item-group.categories-select + .article-list {
    margin-top: $baseline;
}
.layout-news-page .item-group.page,
.layout-articles-page-static .item-group.page {
    @include article-page;
}
.item-group.poll-list {
    @include poll-list(
        $header-border-color: $gray
    );
}
.item-group.poll-question {
    @include poll-question(
        $header-border-color: $gray,
        $background-color: $white
    );
}
.item-group.poll-answers {
    @include poll-answers;
}
.item-group.sign-in-form,
.item-group.forgotten-password-form,
.item-group.reset-password-form,
.item-group.reset-success-page {
    @include login-form;
    margin-top: $baseline * 2;
    .input {
        text-align: left;
    }
}
.item-group.secondary-navigation {
    @include secondary-navigation;
    margin-top: $baseline * 7;
}
.sitemap {
    @include sitemap-basic;
    margin-top: $baseline * 2;
}
.item-group.member-list {
    @include member-list;
}
.item-group.bytebloc {
    @include bytebloc;
}
.item-group.my-profile {
    @include profile-form;
}
.item-group.member-details-main {
    @include member-details-main;
}
.item-group.member-details {
    @include member-details;
}
.avatar {
    @include avatar(
        $width: $baseline * 12,
        $background-color: $secondary-color
    );
}
.avatar-uploader {
    @include avatar-uploader;
}
