body {
    font-family: $body-font-family;
    @include typography('text-medium');
}
p {
    margin: 0;
    margin-top: $baseline;
}
a {
    color: $anchor-color;
    text-decoration: none;
    
    &:focus,
    &:hover {
        color: $anchor-color-hover;
    }
}
.rte a,
p a {
    text-decoration: underline;
}
strong,
b {
    font-weight: $bold;
}
h1{
    margin: 0;
    @include typography('header-a');
}
h2 {
    margin: 0;
    @include typography('header-b');
}
h3 {
    margin: 0;
    @include typography('header-c');
}
h4 {
    margin: 0;
    @include typography('header-d');
}
.text-upper {
    text-transform: uppercase;
}
.text-nowrap {
    display: inline-block;
    margin-right: .5em;
}

@include rte-content;

.rte table {
    font-size: .7em;
    @include breakpoint(medium up) {
        font-size: .8em;
    }
}
