@import 'site:variables';
@import 'site:icon-mixins';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/typography';
@import 'mixins';
.item-group.timeline {
    @include timeline(
        $accent-colors: $primary-color $dark-gray,
        $item-background-color: $white
    );
    .item {
        .date-posted {
            @include typography('text-large');
        }
        .text {
            @include typography('text-large');
        }
        .title {
            @include typography('header-b');
        }
        h2.rte {
            @include typography('header-d');
        }
        h3.rte {
            @include typography('header-e');
        }
    }
    @include breakpoint(smallplus down) {
        margin-left: -#{$gutter * 2};
        .item-wrapper {
            margin-left: $gutter * 2;
            // margin-right: $gutter;
        }
    }
}

.layout-timeline-list {
    @include breakpoint(1600px up) {
        .item-group.page {
            max-width: $content-width;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            .title {
                margin-right: 0;
                padding-left: $baseline;
            }
        }
    }
    .page-layout {
        @include page-layout-timeline;
    }
}

