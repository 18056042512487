.site {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $off-white;
}
.content {
    flex-grow: 1;
}

.header-banner {
    .item-group,
    .sb-display-point-first {
        margin-top: 0;
    }
}

.primary,
.secondary {
    width: 100%;
    max-width: $site-width;
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;
    margin-left: auto;
    margin-right: auto;
}
.primary {
    .item-group:first-child,
    .sb-display-point-first:first-child {
        margin-top: $baseline * 3;
    }
}


.page-layout {
    @include page-layout-base;
}
.layout-home-home,
.layout-member-home-page {
    .page-layout {
        @include page-layout-home;
    }
    .item-group.sign-in-form {
        margin-top: $baseline * 3;
        max-width: none;
    }
    .item-group.article-list {
        .item {
            border: none;
        }
        .head.has-image {
            .image {
                display: none;
            }
            .text-group {
                padding-left: 0;
            }
        }
    }
    .item-group.poll-question {
        .poll-title {
            padding-left: 0;
            padding-right: 0;
        }
        .poll-options {
            margin-left: -$baseline;
            margin-right: -$baseline;
        }
    }
    .item-group.bytebloc {
        flex-grow: 1;
    }
}

.layout-login-sign-in,
.layout-login-forgotten-password,
.layout-login-reset-password,
.layout-login-reset-success {
    .page-layout {
        @include page-layout-minimal;
    }
}

.layout-profile-profile {
    .page-layout {
        @include page-layout-profile;
    }
}

.layout-feed-forum {
    .item-group.forum,
    .item-group.create-post {
        margin-top: 0;
    }
}

// .layout-main-page,
// .layout-expanders-list,
// .layout-overview-page {
// }

.layout-polls-polls,
.layout-polls-page {
    .primary {
        @include poll-page-layout;
    }
}

