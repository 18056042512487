@import 'parent:';
// -------------------------------------------------------
// Sizes
// -------------------------------------------------------
$em: 16;
$baseline: .75rem; //12px
$gutter: 12px;

$site-width: 1200px;

$content-width: 720px;

// //height of sticky part of header
// $sticky-header-height: (
//     small: $baseline * 5
// );
// //height of full header
// $header-height: (
//     small: $baseline * 5,
//     medium: $baseline * 7
// );
// $breakpoints: (
//   small: 0,
//   smallplus: 400px,
//   medium: 668px,
//   large: 1020px,
//   xlarge: 1400px,
//   xxlarge: 1900px,
// );

// -------------------------------------------------------
// Colors
// -------------------------------------------------------
$white: #fff;
// $off-white: #F4F4F4;
$off-white: #f7f4f1;
// $light-gray: #ccc;
$light-gray: #dcd8d2;
$gray: #979797;
$dark-gray: #666;
$black: #000;

$primary-color: #8B0000; //red
$primary-color-light: lighten($primary-color, 10%);
$secondary-color: #007BC4; //blue

$text-color-white: $white;
$text-color-light: $gray;
$text-color-regular: #333;
$text-color-dark: $black;

$anchor-color: $primary-color;
$anchor-color-hover: $primary-color-light;

$message-color: $secondary-color;

$body-font-family: 'Montserrat', sans-serif; 
$header-font-family: $body-font-family;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$typography: (
    header-base: (
        font-family: $header-font-family,
        font-weight: $semibold,
        color: $text-color-regular,
        line-height: 1.2,
    ),
    header-e: (
        base: header-base,
        font-size: #{14/$em}rem,
        line-height: $baseline * 2,
        smallplus: (
            font-size: #{16/$em}rem,
        ),
    ),
    header-d: (
        base: header-base,
        font-size: #{16/$em}rem,
        line-height: $baseline * 2,
        smallplus: (
            font-size: #{18/$em}rem,
        ),
    ),
    header-c: (
        base: header-base,
        font-size: #{16/$em}rem,
        line-height: $baseline * 2,
        smallplus: (
            font-size: #{18/$em}rem,
        ),
    ),
    header-b: (
        base: header-base,
        color: $primary-color,
        font-size: #{20/$em}rem,
        line-height: $baseline * 2,
        smallplus: (
            font-size: #{24/$em}rem,
            line-height: $baseline * 2.5,
        ),
    ),
    header-a: (
        base: header-b,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
        color: $text-color-regular,
    ),
    text-small: (
        base: text-base,
        font-size: #{12/$em}rem,
        smallplus: (
            font-size: #{14/$em}rem,
        ),
    ),
    text-medium: (
        base: text-base,
        font-size: #{14/$em}rem,
        smallplus: (
            font-size: #{16/$em}rem,
        ),
    ),
    text-large: (
        base: text-base,
        font-size: #{16/$em}rem,
        smallplus: (
            font-size: #{18/$em}rem,
            line-height: $baseline * 2,
        ),
    ),
);
